export const compareVersions = (v1, v2) => {
    if (!v1 || !v2) {
        return -1;
    }

    const v1Arr = v1.split('.');
    const v2Arr = v2.split('.');

    for (let i = 0; i < v1Arr.length || i < v2Arr.length; i++) {
        const num1 = parseInt(v1Arr[i]) || 0;
        const num2 = parseInt(v2Arr[i]) || 0;

        if (num1 < num2) {
            return -1; // v2 it's higher
        } else if (num1 > num2) {
            return 1; // v1 it's higher
        }
    }

    // Both versions are the same
    return 0;
}
